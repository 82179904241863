@import '../../../styles/colors';

.cookie-policy-container {
	position: absolute;
	width: 50%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 200px;
	z-index: 500;
	@include primary-gradient;

	&.mobile {
		width: calc(100vw - 80px);
		height: 250px;
	}

	& .cookie-policy {
		margin: 20px;
		font-size: 14px;
		background-color: $white;

		* {
			font-family: 'Open Sans', sans-serif;
		}

		& .cookie-message {
			padding: 5px;
		}

		& .accept-policy {
			margin-top: 20px;
			width: 70%;
			font-weight: bold;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: 20px auto 0 auto;
			padding-bottom: 10px;
		}

	}

	& .actions {
		width: 80%;
		margin: 10px auto 10px auto;

		& .confirm-policy {
			margin: auto;
			text-align: center;
			
			button {
				font-family: 'Open Sans', sans-serif;
				font-weight: bold;
				font-size: 22px;
				height: 40px;
				width: 180px;
				border: none;
				background-color: $accent;
				color: $white;
				transition: all 300ms;

				&:disabled {
					color: lighten($light, 10%);
					background-color: $light;
				}
			}
		}
	}

}