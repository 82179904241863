@import '../../styles/colors';

.nexopedia-item-container {
	width: calc(100vw - 80px);
	margin: auto;
	margin-top: 80px;
	padding: 0 10px;
	height: calc(100vh - 145px);
	overflow-y: scroll;
	overflow-x: hidden;
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: #acacad;

	}

	&::-webkit-scrollbar-thumb {
		background: $secondary;
		min-height: 100px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
	&.mobile{
		width: 100vw;
		margin-top: 40px;
		padding: 0;
		height: calc(100vh - 115px);
		& .nexopedia-item {
			width: 100%;
		}
		& .item-navigation {
			& .previous {
				& .title {
					font-size: 15px;
					width: 150px;
				}
				& div {
					position: relative;
				 & .previous-icon {
						& img {
							margin-top:16px;
							width: 150px;
						}
						
					}
				}
			}

			& .next {
				& .title {
					font-size: 15px;
					width: 150px;
				}
				& div > .next-icon {
					& img {
						margin-top: 16px;
						width: 150px;
					}
					
				}
			}
		}
	}
	& .nexopedia-item {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	& .item-navigation {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		user-select:none;
		padding: 10px 0px;
		@include rarity-1-gradient();
		
		& .previous {
			& div {
				position: relative;
				& .title {
					position: absolute;
					margin-top: 32px;
					font-size: 30px;
					width: 277px;
					text-align: right;
					z-index: 300;
				}
			}
		}

		& .next {
			& div {
				position: relative;
				& .title {
					position: absolute;
					margin-top: 32px;
					font-size: 30px;
					z-index: 300;
				}
			}
		}
	}
}