@import '../../../../../styles/colors';

.element-display-container {
	width: 100px;
	height: 100px;
	margin-right: 10px;
	margin-top: 20px;
	margin-bottom: 20px;

	&.mobile {
		width: 100%;
		margin: auto;
		margin-top: 20px;
		margin-bottom: 20px;
		
		& .element-title {
			margin: auto;
			margin-bottom: 10px;
			
		}

		& .element-display {
			margin: auto;
			& .element-display-icon {
				width: 100%;
				text-align: center;
				margin: auto;
				
			}

			& .element-display-name {
				width: 100%;
				text-align:center;
				margin-top: 5px;
			}
		}
	}

	& .element-title {
		font-size: 18px;
		
		text-align: center;
		margin: auto;
		color: $secondary;
		text-transform: uppercase;
		margin-bottom: 5px;
		margin-left: -8px;
	}

	& .element-display {
		text-align: center;
		& .element-display-icon {
			width: 100%;
			margin: auto;
			& .element-icon-shadow {
				width: 100%;
				text-align: center;
			}
		}

		& .element-display-name {
			width: 100%;
			font-size: 14px;
			text-align: center;
			color: $white;
			text-transform: uppercase;
			
		}
	}

	& .element-display-link {
		width: 100%;
		font-size: 10px;
		color: $light;
		margin: 0 -5px;
		text-decoration: underline;
		text-align: center;
		cursor: pointer;
	}
}