@import '../../styles/colors';

.footer-container {
	height: 75px;
	width: 100%;
	background-color: $primary-light;
	z-index: 100;
	
	&.mobile {
		height: 75px;
	}
	.footer-content {
		&.mobile {
			width: 100vw;
		}
	}
	& .footer-row {

		color: $white;
		font-size: 12px;
		margin: auto;
		display: flex;
		flex-direction: row;
		text-align: center;
		flex-wrap: nowrap;
		height: 20px;

		&.bottom {
			margin-top: 10px;
		}
		
		&.mobile {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
			
			&.bottom {
				
				a {
					line-height: 30px;
					text-align: center;
					margin-top: 10px;
				}
			}

			

			& a {
				line-height: 17px;
				width: 50%;
				
			}
		}

		a {
			font-family: 'Open Sans', sans-serif;
			margin-top: 5px;
			width: 100%;
			color: $dark;
			cursor: pointer;
		}
	}
}