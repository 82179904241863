@import '../../../../../../styles/colors';

.starting-stats-container {
	margin-top: 10px;
	
	&.mobile {
		margin-top: 20px;
		width: 100%;
		
		& .starting-stats {
			width: 90%;
		}
	}
	& .stats-title {
		font-size: 18px;
		
		text-align: left;
		margin-left: 25px;
		color: $secondary;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
	& .starting-stats {
		height: 130px;
		margin: auto;
		border-radius: 12px;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		@include primary-gradient();
		box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.4);
		& .stat-box {
			width: 40px;
			margin: 2px;
			text-align: center;
			display: flex;
			flex-direction: column-reverse;
			justify-content: space-evenly;
			& .stat-title {
				
				border-radius:4px;
				color: $white;
				
			}

			& .stat-value {
				background-color: $white;
				border-radius: 4px;
				line-height: 50px;
				height: 50px;
				font-size: 22px;
				

				&.modified {
					font-size: 14px;
				}
			}

			& .stat-icon {
				img { 
					height: 30px;
					width: 30px;
					padding-top: 5px;
				}
			}
		}
	}
}