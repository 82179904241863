@import '../../styles/colors';

.items-page-container {
	width: 100%;
	margin: auto;
	margin-top: 80px;
	&.mobile {
		width: 100vw;
	}
	& .items {
		width: calc(100vw - 80px);
		margin: auto;

		&.mobile {
			width: 100vw;
		}
		& .items-menu {
			width: calc(100vw - 80px);
			margin: auto;
			

			& .button-group {
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-evenly;
				background-color: $primary-pale;
				& button {
					border:none;
					width: auto;
					padding: 5px;
					font-size: 1.2em;
					text-align: center;
					text-transform: uppercase;
					background-color: transparent;
					color: $black;
					outline: none;
					user-select: none;
					
					&:disabled {
						color: darken($light, 20%);
						pointer-events: none;
					}
					
					&:hover {
						color: $secondary;
					}
				}
			}
			&.mobile{
				width: 100vw;
				& .button-group {
					width: 100vw;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-evenly;
					background-color: $primary-pale;
					& button {
						border:none;
						width: auto;
						padding: 5px;
						font-size: 1.2em;
						text-align: center;
						text-transform: uppercase;
						background-color: transparent;
						color: $black;
						outline: none;
						user-select: none;
						
						&:disabled {
							color: darken($light, 20%);
							pointer-events: none;
						}
						
						&:hover {
							color: $secondary;
						}
					}
				}
			}
		}

		& .items-body {
			
			width: 100%;
			font-size: 14px;
			color: black;
			&.mobile {
				width: 100vw;
			}

			

			&.item-view-container {
				width: 100%;
			}
		}
	}
}