@import '../../styles/colors';

.disclaimer-container {
	position: relative;
    width: calc(100vw - 80px);
	margin: auto;
	margin-top: 65px;
	user-select: none;
	
	
	&.mobile {
		width: 100vw;
    	height: calc(100vh - 115px);
		overflow-y: scroll;
		margin-top: 40px;
	}
	& .content {
		font-family: 'Open Sans', sans-serif;
		width: 90%;
		height: 800px;
		margin: auto;
		background-color: $white;
		overflow-y: scroll;
		padding:15px;
		box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.3);

		* {
			font-family: 'Open Sans', sans-serif;
		}
	}

	a {
		overflow-wrap: break-word;
	}
}