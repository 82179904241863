@import '../../styles/colors';

.item-view-container {
	width: 100%;
	height:calc(100vh - 250px);
	overflow-y: scroll;

	& .mobile {
		width: 100vw;
	}
	& .category-header {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 285px;
		
		&.extended {
			height: 310px;
		}
		
		& .category-title {
			height: 100px;
			width: 80%;
			margin: auto;
			text-align: center;
			line-height: 100px;
			color: $white;
			font-size: 50px;
			text-transform: uppercase;
			background-color: $primary-pale;

			&.shorten {
				font-size: 42px;
			}
		}

		& .category-help {
			text-align: center;
			color: $dark;
			margin-bottom: 20px;
			cursor: pointer;
		}
	}

	& .item-view {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin: auto;
		flex-wrap: nowrap;
		width: 70%;
		margin-bottom: 20px;

		&::-webkit-scrollbar {
			width: 8px;
		}
	
		&::-webkit-scrollbar-track {
			background: #acacad;
	
		}
	
		&::-webkit-scrollbar-thumb {
			background: $secondary;
			min-height: 100px;
		}
	
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
		
		&.mobile {
			height:calc(100vh - 470px);
			flex-direction: column-reverse;
		}
		& .item-view-icon {
			width: 200px;
    		pointer-events: none;
    		margin: auto;
		}
		& .item-details {
			width: 50%;
			
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			&.mobile {
				width: 90%;
				margin: auto;
			}
			& .item-view-name {
				width: 100%;
				font-size: 30px;
				text-align: center;
				margin-bottom: 20px;
			}

			& .item-view-description {
				width: 100%;
				margin: auto;
				font-size: 18px;
			}
		}
		

	
	}
	
}