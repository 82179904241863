@import '../../node_modules//normalize.css/normalize.css';
@import './fonts';
@import './colors';

html, body {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}