@import '../../styles/colors';

.category-view-container {
	width: 100%;
	overflow-y: scroll;
	height: calc(100vh - 225px);
	& .mobile {
		width: 100vw;
	}
	& .category-header {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 275px;
		& .category-title {
			height: 100px;
			width: 80%;
			margin: auto;
			text-align: center;
			line-height: 100px;
			color: $white;
			font-size: 50px;
			text-transform: uppercase;
			background-color: $primary-pale;

			&.shorten {
				font-size: 42px;
			}
		}

		& .category-help {
			text-align: center;
			color: $dark;
			margin-bottom: 20px;
		}
	}

	& .category-view {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin: auto;
		flex-wrap: wrap;
		width: 100%;
		
		
		margin-bottom: 20px;
		
		&::-webkit-scrollbar {
			width: 8px;
		}
	
		&::-webkit-scrollbar-track {
			background: #acacad;
	
		}
	
		&::-webkit-scrollbar-thumb {
			background: $secondary;
			min-height: 100px;
		}
	
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}

		& .item-card {
			width: 125px;
			height: 125px;
			cursor: pointer;
			& .item-card-icon {
				width: 100px;
				margin: auto;
			}
			& .item-card-name {
				width: 125px;
				font-size: 12px;
				text-align: center;
			}

		}
	}
	
}