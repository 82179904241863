@import '../../../../../styles/colors';

.food-container {
	width: calc(100% - 30px);
	height: 100px;
	margin-left: 30px;
	user-select: none;
	pointer-events: none;
	
	&.mobile {
		margin: 30px auto 0 auto;
		width: calc(100vw - 40px);
	}
	
	& .food-title {
		font-size: 18px;
		
		text-align: center;
		text-align: left;
		color: $secondary;
		text-transform: uppercase;
		margin-bottom: 5px;
		
	}

	& .food-action-help {
		line-height: 10px;
		font-size: 10px;
		color: $white;
		margin-top: 3px;
		text-align: center;
	}
	& .food-body {
		height: 100px;
		background-color: $light;
		border-radius: 12px;
		width: 100%;
		color: $dark;
		
		line-height: 100px;
		font-size: 18px;
		text-transform: uppercase;
		text-align: center;
		box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.4);

		& .food-item-container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			height: 100%;
			width: 100%;
			justify-content: space-around;

			& .food-detail {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100px;
				width: 100px;
				pointer-events: all;
				cursor: pointer;
				& .food-icon {
					height:60px;
					width:60px;
					margin: 5px auto 0 auto;
				}

				& .food-name {
					font-size: 10px;
					line-height: 12px;
				}
			}
		}	
	}
}