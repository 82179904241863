@import '../../../../styles/colors';

.element-chart-container {
	width: calc(100vw - 80px);
	overflow-y: scroll;
	height: calc(100vh - 175px);
	margin-top: 30px;
	background-color: $primary;
	color: $white;
	&.mobile {
		width: 100vw;
	}

	& .chart-container {
		& .element-description {
			width: 90%;
			margin: auto;
		}

		& .link-group {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-evenly;
			margin: 0px auto;

			& div {
				& a {
					color: inherit;
					text-decoration: none;
					&:active {
						text-decoration: none;
					}
				}
			}

			& .hint {
				font-size: 12px;
				width: 100%;
				text-align: center;
				margin-bottom: 20px;
			}
		}
	}

	& .element-chart {
		& .element-title {
			width: 90%;
			margin: auto;
			font-size: 18px;
			line-height: 30px;
			text-transform: uppercase;
		}
		& .element-description {
			width: 90%;
			margin: auto;
		}
	}
}