@import '../../styles/colors';

.navbar-container-mobile {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 40px;
	background-color: $primary-light;
	z-index: 600;
	user-select: none;

	.navbar {
		display: flex;
		flex-direction: row;
		max-width: 100%;
		justify-content: space-between;
		margin: auto;
		
		& .menu-button {
			position: absolute;
			top: -4px;
			left: 5px;
			img {
				filter: invert(100%);
			}
		}

		& .navbar-icon {
			cursor: pointer;
			width: 100px;
			margin: auto;
			img {
				height: 30px;
				padding-left: 10px;
				padding-right: 10px;
				padding-top: 5px;
				
			}

			&.rotate {
				img {
					transform: rotate(90deg);
				}
			}
		}

		& .menu-items {
			position: absolute;
			top: 40px;
			width: 200px;
			display: flex;
			border-bottom-right-radius: 12px;
			flex-direction: column;
			font-size: 12px;
			color: $secondary;
			justify-content: space-evenly;
			height: 0px;
			overflow: hidden;
			transition: all 300ms;
			background-color: $primary-light;
			padding: 0px;
			left:0;

			&.show {
				height: 300px;
				padding: 5px;
			}

			& .menu-item {
				width: 100%;
				cursor: pointer;
				display: flex;
				flex-direction: row-reverse;
				justify-content: space-between;
				padding: 5px;
				background-color: white;

				img {
					height: 50px;
					width: 50px;
					margin-left: 20px;
				}

				& .menu-item-background {
					width: 100%;

					& .menu-item-text {
						font-size: 16px;
						text-align: center;
						width: 100%;
						margin-top: 12px;
						color: black;
					
					}
				}
			}
		}
	}
}
.navbar-container {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 80px;
	background-color: $primary-light;
	z-index: 505;
	user-select: none;
	.navbar {
		display: flex;
		flex-direction: row;
		width: calc(100vw - 80px);
		justify-content: flex-start;
		margin: auto;

		& .navbar-icon {
			cursor: pointer;
			img {
				height: 50px;
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 5px;
			}

			&.rotate {
				img {
					transform: rotate(90deg);
				}
			}
		}
		& .menu-items {
			width: 80%;
			display: flex;
			flex-direction: row;
			font-size: 20px;
			color: $secondary;
			justify-content: flex-end;
			
			& .menu-item {
				position: relative;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				width: 100px;
				margin-right: 20px;
				
				&.disabled {
					width: 100px;
					user-select: none;
					pointer-events: none;
					filter: brightness(50%);

					& .menu-item-background {
						& .menu-item-text {
							color: $light;
						}
					}
				}

				& .menu-item-background {
					position: absolute;
					top: 35px;
					height: 35px;
					width: 100%;
					border-radius: 12px;
					z-index: -1;
					& .menu-item-text {
						text-align: center;
						line-height: 60px;
						font-size: 12px;
						color: $black;
					}
				}
				
				img {
					height: 50px;
					width: 50px;
					margin: 5px auto;
				}
			}
		}
		& .navbar-icon {
			cursor: pointer;
			img {
				height: 30px;
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 20px;
			}

			&.rotate {
				img {
					transform: rotate(90deg);
				}
			}
		}
	}
}