@import '../../../../../styles/colors';

.skills-container {
	width: 100%;
	margin: 50px auto;
	user-select: none;

	&.mobile {
		width: calc(100vw - 40px);
		margin: 50px auto;
	}
	& .skills-title {
		font-size: 18px;
		
		text-align: center;
		text-align: left;
		color: $secondary;
		text-transform: uppercase;
		margin-bottom: 5px;
	}

	& .skills-body {
		background-color: $light;
		border-radius: 12px;
		width: 100%;
		color: $dark;
		line-height: 150px;
		font-size: 18px;
		text-transform: uppercase;
		text-align: center;
		box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.4);

		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			background: #acacad;

		}

		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}

		&.mobile {
			width: auto;
			&::-webkit-scrollbar {
				width:0;
			}
	
			&::-webkit-scrollbar-track {
				background: transparent;
	
			}
	
			&::-webkit-scrollbar-thumb {
				background: transparent;
			}
	
			&::-webkit-scrollbar-thumb:hover {
				background: transparent;
			}
		}
		& .skill-items {
			width: 100%;
			display: flex;
			flex-direction: column;

			&.mobile {
				width: calc(100% - 4px);
			}

			& .skill-item {
				width: 100%;
				height: 240px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				border-bottom: 3px solid $black;

				&.mobile {
					width: 100%;
					height: 280px;
				}
			
				& .skill-level {
					height: 100%;
					width: 120px;
					background-color: #ACACAD;
					font-size: 18px;
					line-height: 240px;
					
					&.mobile {
						font-size: 12px;
						line-height: 300px;
						width: 80px;
					}
				}

				& .skill-display {
					width: calc(100% - 120px);
					background-color: $black;
					border: 4px solid $dark;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					
					&.mobile {
						width: calc(100% - 80px);
					}

					&.gen-2 {
						width: 100%;
					}
					& .skill-hero {
						width: 215px;
						height: 215px;
						border-right: 3px solid $white;
						
						&.mobile {
							position: relative;
							width: 80px;
							height: 80px;
							border-right: none;
						}

						& .skill-icon {
							margin: 10px auto;
							pointer-events: none;
							&.mobile {
								position: absolute;
								top: -10px;
							}
						}
					}
					
					& .right-container {
						width: calc(100% - 180px);
						margin-top: 10px;

						&.mobile {
							width: 100%;
  							margin-left: -80px;
							
						}

						&.gen-2 {
							width: 100%;
						}

						& .skill-header {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							width: 350px;
							height: 50px;
							border-bottom: 3px solid $light;

							&.mobile {
								width: 100%;
								height: 30px;
							}

							&.gen-2 {
								width: 96%;
							}
							& .skill-name {
								line-height: 50px;
								color: $white;
								font-size: 18px;
								margin-left: 20px;
								height: 100%;

								&.mobile {
									line-height: 14px;
									font-size: 12px;
									margin-left: 90px
								}
							}
							& .skill-element {
								height: 75px;
								border-radius: 100%;
								border: 2px solid white;
								line-height: 0;
								background: white;
								margin-right: -20px;

								&.mobile {
									height: 30px;
									width: 30px;
									margin-right: 0;
									& > * {
										height: 30px;
										width: 30px;
									}
								}
							}
							
						}

						& .skill-description {
							color: white;
							font-size: 15px;
							height: 80px;
							width: 95%;
							margin-top: 35px;
							margin-left: 10px;
							text-align: left;
							line-height: 16px;

							&.mobile {
								width: 100%;
								font-size: 12px;
								margin-top: 60px;
								
								
							}
						}

						& .skill-footer {
							height: 100px;
							line-height: 12px;
							font-size: 12px;
							color: white;

							& .skill-details {
								height: 100%;
								width: 100%;

								&.mobile {
									table {
										th {
											font-size: 12px;
										}
									}
								}
								table {
									width: 100%;
									border-collapse: collapse;
									border-bottom-color: white;

									th {
										font-size: 16px;
										line-height: 16px;
										border-bottom: 1px solid white;
									}

									td {
										font-size: 16px;
										line-height: 16px;
										padding-top: 8px;
									}
								}
							}
						}
						
					}

					
				}
			}
		}
	}
}