@import '../../styles/colors';

.social-page-container {
	position: relative;
	width: calc(100vw - 80px);
	margin: auto;
	margin-top: 85px;

	&.mobile {
		height: 100%;
		width: 100vw;
		
	}

	& .social-page {
		width: 800px;
		height: calc(100vh - 170px);
		border-radius: 4px;
		margin: auto;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 8px;
		}
	
		&::-webkit-scrollbar-track {
			background: #acacad;
	
		}
	
		&::-webkit-scrollbar-thumb {
			background: $secondary;
			min-height: 100px;
		}
	
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}

		&.mobile {
			height: calc(100vh - 170px);
			
			width: calc(100vw - 60px);
			overflow-y: scroll;
			margin: auto;
		}
	}
}