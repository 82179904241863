@import '../../../styles//colors';

.walkthrough-container {
	max-width: calc(100% - 100px);
	overflow-y: scroll;
	height: calc(100vh - 175px);
	margin: auto;
	margin-top: 30px;
	background-color: $primary;
	color: $white;

	&.mobile {
		width: 100vw;
		max-width: 100vw;
	}
	& .walkthrough-title {
		font-size: 22px;
		text-align: center;
	}

	& .details {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
			
		& .walkthrough-list {
			width: 100%;
			margin: 30px;
			display: flex;
			flex-direction: column;
			font-size: 18px;

			a {
				margin: 30px;
				text-decoration: none;
				color: $white;
			}
		}
	}

	& .walkthrough-images {
		width: calc(100% - 50px);
		margin: auto;
		
		&.mobile {
			width: 100vw;
		}

		
	}
}