@import '../../../../../styles/colors';

.evolution-container {
	width: 100%;
	height: 100px;

	&.mobile {
		width: calc(100vw - 40px);
		margin: 10px auto;
	}

	& .evolution-title {
		font-size: 18px;
		text-align: center;
		text-align: left;
		color: $secondary;
		text-transform: uppercase;
		margin-bottom: 5px;
		
	}

	& .evolution-body {
		height: 100px;
		background-color: $white;
		border-radius: 12px;
		width: 100%;
		color: $dark;
		
		line-height: 100px;
		font-size: 18px;
		text-transform: uppercase;
		text-align: center;
		box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.4);

		& .items-container {
			display: flex;
			flex-direction: row;
			height: 100%;
			width: 100%;
			pointer-events: all;
			user-select: auto;
			&.shift {
				justify-content: space-evenly;
			}

			&.center {
				justify-content: center;		
			}

			justify-content: space-between;
			& a {
				color: inherit;
				text-decoration: none;
				& .evo-item {
					width:100px;
					margin-top: 5px;
					
					&.mobile {
						margin-top: 10px;
					}
					
					& .evo-item-title {
						margin-top: 4px;
						line-height: 12px;
						font-size: 10px;
						color: #40403E;
						display: flex;
						flex-direction: row;
						justify-content: space-evenly;
					}

					& .canvas-container {
						position: relative; 
						width: 100%; 
						height: 100px;
						cursor:pointer;
						&.gen-2 {
							height: 75px;
							width: 75px;
							shape-outside: circle();
							clip-path: circle();
							left: 10px;
							margin-top: 10px;
						}
						&.mobile {
							

							&.gen-2 {
								height: 75px;
								width: 75px;
								shape-outside: circle();
								clip-path: circle();
								left:10px; 
							}
						}
					}

					& .evo-item-level {
						position: relative;
						top: -105px;
						left: 65px;
						
						font-size: 12px;
						display:flex;
						flex-direction: column;

						& span {
							height: 10px;
						}

						&.gen-2 {
							top: -90px;
							left: 60px;
						}
						&.mobile {
							top: -115px;
							left: 55px;
							flex-direction: column;

							& span {
								margin-left: 5px;
							}
							&.gen-2 {
								top: -90px;
								left: 50px;
							}
						}
					}

					&.shift {
						& .evo-item-level {
							left: 75px;
						}
						
						&.mobile {
							.evo-item-level {
								left: 85px;
							}
						}
					}
				}
			}		
		}
	}
}