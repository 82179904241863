@import '../../../styles//colors';

.status-effect-container {
	width: calc(100vw - 80px);
	overflow-y: scroll;
	height: calc(100vh - 175px);
	margin-top: 30px;
	background-color: $primary;
	color: $white;

	&.mobile {
		width: 100vw;
	}
	& .guide-effects {
		margin: 50px 0;
		& .status-effect-head {
			width: 90%;
			margin: 0 auto 20px auto;
			font-size: 18px;
			background-color: $primary-pale;
			text-align: center;
		}

		& .status-effects {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			width: 90%;
			margin: auto;
			& .status-effect-item {
				background-color: $white;
				border-radius: 10px;
				margin-bottom: 20px;
				& div {
					display:flex;
					flex-direction: row;
					justify-content: space-between;
					color: $black;
					text-align: center;
					& .status-effect-icon {
						width: 40px;
						height: 40px;
						margin: 10px 20px;
					}

					& .status-effect-name {
						font-size: 16px;
						font-weight: bold;
						margin: 10px;
					}
				}

				& .status-effect-description {
					margin: 10px 20px;
					text-align: left;
				}

				& .status-effect-skills {
					display: flex;
					flex-direction:column;

					& .skill-group-title {
						color: $black;
						font-size: 14px;
						margin: 10px auto;
						text-align: center;
					}

					& .skill-group {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: space-evenly;
						width: 90%;
						margin: 10px auto;
						text-align: center;
			
						& .skill-container {
							display: flex;
							flex-direction: column;
							width: 60px;
							height: 80px;
							margin: auto;
							& .skill-name{
								
								font-size: 10px;
								color: $black;
								margin: auto;
							}

							& .skill-icon {
								margin: 3px auto;
								canvas {
									height: 40px;
									width: 40px;
								}
							}
						}
					}
				}
			}
		}
	}
}