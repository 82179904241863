@import '../../../../styles/colors';

.type-comparison-container {
	width: 90%;
	margin: 20px auto;
	background-color: $primary-pale;
	padding: 20px;

	&.mobile {
		width: calc(100vw - 80px);
	}
	& .type-comparison {
		padding: 20px;
		background-color: $white;
		border-radius: 12px;
		& .type-comparison-header {
			width: 100%;
			text-align: center;

			div {
				width: 100%;
				color: $dark;
			}
		}

		& .comparison-container {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			width: 100%;
			margin: auto;
			
			&.mobile {
				margin: 0;
			}
			& .comparison-icon {
				margin-right: 10px;

				&.mobile {
					
					img {
						width: 70px;
					}
				}
			}

			& .comparison-list {
				width: calc(100% - 100px);
				height: 100px;
				margin: auto;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				
				div {
					margin-left: 2px;
					color: $dark;
					font-size: 10px;
				}

			}
		}
	}
}