@import '../../../../../../styles/colors';

.stat-calculator-container {
	width: calc(100% - 70px);
    height: 100px;
    margin-left: 30px;

	&.mobile {
		width: 100vw;
		height: 225px;
		margin-bottom: 0px;
		margin: 20px auto 25px auto;
	}

	& .stat-calculator-title {
		font-size: 18px;
		
		text-align: left;
		color: $secondary;
		text-transform: uppercase;
		margin-bottom: 5px;
		
		&.mobile {
			margin-left: 20px;
		}
	}

	& .stat-calculator-body {
		height: 212px;
		@include primary-gradient();
		border-radius: 12px;
		width: 100%;
		color: #40403E;
		
		line-height: 100px;
		font-size: 18px;
		text-transform: uppercase;
		text-align: center;
		box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.4);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		&.mobile {
			width: calc(100vw - 40px);
			margin: auto;
		}
		& .stats-row {
			display: flex;
			flex-direction: row;

			&.mobile {
				justify-content: space-around;
				margin: 15px 0 0 -10px;
			}
			
		}
		& .level-type-selector {
			pointer-events: all;
			top: 20px;
			position: relative;
			
			&.mobile {
				top: 0;
				position: initial;
			}
			& .title {
				height: 15px;
				width: 120px;
				position: relative;
				color: #fff;
				z-index: 30;
				top: -42px;
				left: 20px;
				font-size: 12px;

				&.mobile {
					width: 98px;
				}
			}

			& .switch {
				position: relative;
				top: -22px;
				left: 13px;
				height: 94px;
				width: 120px;
				padding: 10px;
				border-radius: 4px;
				background-color: darken(#6457CD, 4%);

				&.mobile {
					height: 72px;
					width: 90px;
				}
				& .regular {
					position: relative;
					width: 100px;
					height: 20px;
					margin-top: 3px;
					margin-left: 10px;
					z-index: 20;
					top: -18px;
					color: #6b6b65;
					transition: all 300ms;

					&.shift {
						color: $black;
					}

					&.mobile {
						width: 90px;
						margin-top:0px;
						margin-left: 1px;
						font-size: 14px;
					}
				}
				& .reborn {
					width: 60px;
					height: 20px;
					margin-top: 10px;
					margin-left: 17px;
					top: -10px;
					left: 4px;
					text-align: center;
					position: relative;
					z-index: 20;
					color: #6b6b65;
					transition: all 300ms;
					&.shift {
						color: $black;
					}
					&.mobile {
						margin-top: 0px;
						margin-left: 6px;
						font-size: 14px;
						left: 7px;
					}
				}
				& .slider {
					position: absolute;
					width: 115px;
					height: 40px;
					left: 13px;
					top: 26px;
					background-color: #CDCDCC;
					z-index: 10;
					border-radius: 12px;
					color: #CDCDCC;
					transition: all 300ms;

					&.shift {
						top: 60px;

						&.mobile {
							top: 54px;
						}
					}

					&.mobile {
						width: 85px;
						height: 30px;

					}
				}
				& .channel {
					height: 81px;
					width: 120px;
					position: absolute;
					background-color: #3a3a3c;
					z-index: 5;
					top: 23px;
					border-radius: 12px;

					&.mobile {
						height: 65px;
						width: 91px;
					}
				}
			}
		}
		& .level-selector {
			pointer-events: all;
			top: 20px;
			position: relative;
			left: 30px;
			
			&.mobile {
				top: 0;
				left: 0;
				position: initial;
			}

			& .title {
				height: 15px;
				width: 120px;
				position: relative;
				color: #fff;
				z-index: 30;
				top: -42px;
				left: 17px;
				font-size: 12px;

				&.mobile {
					width: 90px;
				}
			}

			& .container {
				position: relative;
				top: -22px;
				left: 13px;
				height: 94px;
				width: 120px;
				padding: 10px;
				border-radius: 4px;
				background-color: darken(#6457CD, 4%);

				&.mobile{
					left: 4px;
					height: 72px;
					width: 90px;
				}
			}
			& .number-input {
				position: relative;
				width: 100px;
				background-color:#3a3a3c;
				border: none;
				border-bottom: 2px solid $white;
				padding: 5px;
				font-size: 20px;
				-webkit-border-radius: 0; 
				border-radius: 0;
				color: $white;
				z-index: 20;
				text-align: center;

				&:focus {
					outline: none;
				}

				&.mobile {
					width: 70px;
					font-size: 15px;
					top: -10px;
				}

			}
			& .channel {
				height: 81px;
				width: 120px;
				position: absolute;
				background-color: #3a3a3c;
				z-index: 5;
				top: 23px;
				border-radius: 12px;

				&.mobile {
					height: 60px;
					width: 90px;
				}
			}
		}

		& .stats-display-row {
			height: 80px;
			width: 100%;
			margin-top: -5px;
			margin-left: -10px;
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;

			&.mobile {
				height: 80px;
				width: 95%;
				margin: -10px 0 0 0;
			}
			& .stat-box {
				width: 40px;
				height: 80px;
				padding: 5px;
				text-align: center;
				display: flex;
				flex-direction: column-reverse;
				justify-content: flex-start;

				&.mobile {
					height: 90px;
					margin-left: 5px;
					justify-self: space-evenly;
				}
				& .stat-title {
					
					border-radius:4px;
					color: $white;
					height: 15px;
					margin-left: 14px;
					position: relative;
					top: -42px;
					font-size: 16px;

					&.mobile {
						margin-left: 0px;
						width: 50px;
						text-align: center;
						margin-top: 5px;
					}
				}
	
				& .stat-value {
					background-color: #f4f4f4;
					border-radius: 4px;
					line-height: 60px;
					height: 60px;
					width: 60px;
					font-size: 22px;
					
					font-size: 14px;
					& span{
						position: relative;
						top: 10px
					}
					&.modified {
						font-size: 12px;
					}
					&.mobile {
						font-size: 10px;
						width: 50px;
					}
				}
	
				& .stat-icon {
					position: relative;
					top: -8px;
					left: 10px;
					height: 20px;
					img { 
						height: 20px;
						width: 20px;
						padding-top: 5px;
					}
					&.mobile {
						left: 3px;
						top: -8px;
					}
				}
			}
		}
	}
}