$white: #f4f4f4;
$black: #1B1B1B;
$primary: #392f8a;
$secondary: #F8DC3D;
$terinary: #F04164;
$dark: #40403E;
$light: #CDCDCC;
$accent: #1864E5;

$primary-light: #efeef8;
$primary-background: darken(#efeef8, 20%);
$primary-pale: darken($primary-light, 30%);

$rarity1: #cacaca; //common
$rarity2: #80cf3f; //uncommon
$rarity3: #2fd5e8; //rare
$rarity4: #BD3FFA; //mega rare
$rarity5: #de5147; //ultra rare
$rarity6: #fdae53; //legendary
$rarity7: #264ba3; //ultimate

@mixin primary-gradient {
	background: linear-gradient(240deg, $primary 0%, lighten($primary, 5%) 25%, lighten($primary, 15%) 35%, lighten($primary, 10%) 65%, $primary 100%);
}

@mixin dark-gradient {
	background: linear-gradient(240deg, #40403e 0%, #40403e 15%, #9a9a9a 35%, #40403e 50%, #40403e 100%);
}

@function set-tint($color, $tint:null) {
	@if $tint {
		@return lighten($color, $tint + unquote("%"));
	}
	@else {
		@return lighten($color, 12.5%);	
	}
}

@function set-shade($color, $shade: null) {
	@if $shade {
		@return darken($color, $shade + unquote("%"));
	}
	@else {
		@return darken($color, 12.5%);
	}
}

@mixin rarity-1-gradient() {
	background: linear-gradient(120deg, $rarity1 0%, lighten($rarity1, 5%) 36%, $white 63%, darken($rarity1, 10%) 100%);
}

@mixin rarity-2-gradient(){
	background: linear-gradient(120deg, $rarity2 0%, lighten($rarity2, 5%) 36%, lighten($rarity2, 20%) 63%, darken($rarity2, 10%) 100%);
}

@mixin rarity-3-gradient() {
	background: linear-gradient(120deg, $rarity3 0%, lighten($rarity3, 5%) 36%, lighten($rarity3, 20%) 63%, darken($rarity3, 10%) 100%);
}

@mixin rarity-4-gradient() {
	background: linear-gradient(120deg, $rarity4 0%, lighten($rarity4, 5%) 36%, lighten($rarity4, 20%) 63%, darken($rarity4, 10%) 100%);
}

@mixin rarity-5-gradient() {
	background: linear-gradient(120deg, $rarity5 0%, lighten($rarity5, 5%) 36%, lighten($rarity5, 20%) 63%, darken($rarity5, 10%) 100%);
}

@mixin rarity-6-gradient() {
	background: linear-gradient(120deg, $rarity6 0%, lighten($rarity6, 5%) 36%, lighten($rarity6, 20%) 63%, darken($rarity6, 10%) 100%);
}

@mixin rarity-7-gradient() {
	background: linear-gradient(120deg, $rarity7 0%, lighten($rarity7, 5%) 36%, lighten($rarity7, 20%) 63%, darken($rarity7, 10%) 100%);
}