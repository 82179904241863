@import '../../styles/colors';

.nexopedia-page-container {
	position: relative;
	width: calc(100vw - 80px);
	height: calc(100vh - 155px);
	margin: auto;
	margin-top: 80px;
	user-select: none;
	overflow-y: scroll;
	overflow-x: hidden;
	

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: #acacad;

	}

	&::-webkit-scrollbar-thumb {
		background: $secondary;
		min-height: 100px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	&.mobile {
		width: 100vw;
		height: calc(100vh - 115px);
		margin-top: 40px;
	}

	& .title-top {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		width: 100%;
		height: 300px;
		background-color:$primary;
		padding-top: 30px;
		overflow-y: hidden;
		transition: all 400ms;

		&.advanced {
			height: 750px;
		}

		& .search-tab {
			position: relative;
			bottom: 40px;
			height: 40px;
			width: 100px;
			
			left: 50%;
			transform: translate(-50%, 0);
			border-radius: 8px;
			color: $white;
			text-decoration: underline;
			font-size: 18px;
			text-align: center;
			line-height: 40px;
			font-variant: small-caps;
		}

		& .advanced-hint {
			width: 100%;
			text-align: center;
			color: $white;
			margin-top: 20px;
			font-size: 14px;
			height: 80px;
		}

		& .generation-selector {
			margin: auto;
		}
		& .search-input {
			width: 305px;
			margin: auto;
		}
		&.mobile {
			
			width: 100vw;
			height: 300px;

			&.advanced {
				height: 800px;
			}

			
			& .search-tab {
				position: relative;
				bottom: 40px;
				height: 40px;
				width: 100px;
				
				left: 50%;
				transform: translate(-50%, 0);
				border-radius: 8px;
				color: $white;
				text-decoration: underline;
				font-size: 18px;
				text-align: center;
				line-height: 40px;
				font-variant: small-caps;
			}

			& .advanced-hint {
				width: 100%;
				text-align: center;
				color: $white;
				margin-top: 20px;
				font-size: 14px;
				height: 80px;
			}
			& .search-container{
				width: 300px;
				margin: 10px auto;
			

				& .reset {
					margin-top: 0px;
				}


			}

			& .instruction {
				margin: 10px auto;
				text-align: center;
			}
		}

		& .search-container {
			width: 80%;
			font-size: 18px;
			height: 300px;
			
			& .link-group {
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-evenly;
				margin: -20px 5px;
				
				& div {

					color: inherit;
					text-decoration: none;
					& .element-display-container {
						width: 80px;
						height: 50px;
						& .element-display-name {
							margin-top: -20px;
							font-size: 10px;
							color: $white;
						}
					}
					&:active {
						text-decoration: none;
					}
				}
			}
			
			& .generation-selector {
				position: relative;
				width: 300px;
				height: 100px;
				text-align: center;
				border-radius: 8px;
				background-color: $dark;
				margin-bottom: 20px;
				border: 3px solid $primary-light;
				& .slider {
					width: 300px;
					height: 50px;
					position: absolute;
					background-color: $primary;
					border-radius:8px;
					top:0;
					z-index: 1;
					
					&.switched {
						top: 50px;
					}
				}
				div {
					img {
						position: relative;
						height: 50px;
						filter: grayscale(1);
						z-index: 2;
						&.selected {
							filter: grayscale(0);
						}
					}
				}
			}

			& .search-input {
				display: flex;
				flex-direction: row;
				background-color: $accent;
				border-radius: 8px;
				user-select: text;
				& input {
					border: none;
					margin-left: 5px;
					margin-top: 5px;
					height: 20px;
					padding: 10px 8px;
					width: 280px;
					user-select: text;
				}

				& button {
					height: 50px;
					width: 60px;
					border: none;
					border-radius: 8px;
					background-color: $accent;
					color: $white;
				}	
			}
			& .reset {
				font-size: 16px;
				line-height: 20px;
				text-decoration: underline;
				color: $light;
				text-align: center;
				position: relative;
				bottom: 100px;
				opacity: 1;
				transition: all 300ms;
				cursor: pointer;
				pointer-events: all;
				user-select: text;

				&.hide {
					pointer-events: none;
					opacity: 0;
				}
			}

			& .rarity-search {
				width: 100%;
				height: 180px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-evenly;

				& .rarity-container {
					height: 65px;
					width: 65px;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-evenly;

					&.mobile {
						height: 40px;
						margin-top:5px;
					}
					& .rarity-box {
						margin-bottom: 5px;
						height: 40px;
						width: 40px;
						border: 2px solid $white;
						border-radius: 8px;
						font-size: 30px;
						font-weight: bolder;
						text-align: center;
						line-height: 40px;
						&.mobile {
							height: 30px;
							width: 30px;
							font-size: 20px;
	
							&.rarity-2 {
								font-size: 18px;
							}
	
							&.rarity-4 {
								font-size: 18px;
							}
	
	
						}
						&.rarity-1 {
							background-color: $rarity1;
						}
	
						&.rarity-2 {
							background-color: $rarity2;
							font-size: 24px;
						}
	
						&.rarity-3 {
							background-color: $rarity3;
						}
	
						&.rarity-4 {
							background-color: $rarity4;
							font-size: 24px;
						}
	
						&.rarity-5 {
							background-color: $rarity5;
						}
	
						&.rarity-6 {
							background-color: $rarity6;
						}
	
						&.rarity-7 {
							background-color: $rarity7;
						}
					}
	
					& .rarity-title {
						width: 100%;
						font-size: 12px;
						color: $white;
						text-transform: uppercase;
						text-align: center;
	
						&.mobile {
							font-size: 8px;
						}
					}
				}
			}
		}
		& .instruction{
			font-size: 20px;
			width: 300px;
			height: 40px;
			margin-top: 10px;
			padding: 20px 10px;
			text-align: center;
			border: 2px solid $white;
			color: $white;
			background-color: $accent;
			text-transform: uppercase;
		}

		
	}
	& .nexomon-cards {
		width: 100%;
		//height: calc(100vh - 410px);
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		
		a {
			text-decoration: none;
			color: inherit;
			margin-bottom: 30px;
		}
		&.mobile {
			width: 100vw;
			//height: calc(100vh - 410px);
			justify-content: space-around;

			a {
				margin-bottom: -10px;
			}
		}

	}
}
