@import '../../../../styles/colors';


@mixin content-row($width: 100%) {
	& .content-row {
		
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: auto;

		&.mobile {
			width: 100%;
			justify-content: space-evenly;
			flex-direction: column;
		}
	}
}
.nexomon-card-container {
	user-select: none;
	width: calc(100vw - 80px);
	
	@include dark-gradient();

	&.mobile {
		width: 100%;
		margin: 0px auto;
		border-radius: 0;
	}

	& .nexomon-card {
		margin-top: 25px;
		position: relative;

		&.mobile {
			width: 100%;
		}

		& .close-card {
			font-size: 40px;
			color: #f4f4f4;
			position: absolute;
			left: 760px;
			top: -25px;
			
			font-variant-caps: all-petite-caps;
			cursor: pointer;

			&.mobile {
				left: calc(100vw - 35px);
				top: -27px;
			}
		}

		& .top-content {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			&.mobile{
				flex-direction: column;
			}

			& .top-content-left{
		
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				width: 45%;
				margin-left: 40px;
				margin-top: 15px;

				&.mobile {
					width: 100%;
					margin-left: 0px;
					margin-top: 20px;
				}

				& .hero-image {
					height: 350px;
					width: 350px;
					margin: auto;

					&.mobile {
						width: calc(100vw - 50px);
						height: calc(100vw - 50px);
					}

					& .rarity-type {
						font-size: 22px;
						
						color: $black;
						position: absolute;
						top: 20px;
						left: 50px;
						user-select: none;

						&.shadow {
							top: 22px;
							left: 52px;
							
							color: $white;
							&.mobile {
								top: 32px;
								left: 42px;
							}
						}


						&.mobile {
							top:30px;
							left: 40px;
							font-size: 15px;
						}


					}

					& .list-number {
						font-size: 22px;
						
						color: $black;
						position: absolute;
						top: 20px;
						left: 325px;
						user-select: none;
						

						&.shadow {
							top: 22px;
							left: 327px;
							color: $white;
							
							&.mobile {
								top: 32px;
								left: calc(100vw - 73px);
							}
						}

						&.mobile {
							font-size:15px;
							left: calc(100vw - 75px);
							top: 30px;
						}
					}
					
				}
			}
			& .top-content-right {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: 60%;
				@include content-row (250px);

				&.mobile {
					width: 100%;
				}

				& .card-title {
					width: 100%;
					text-align: center;
					font-size: 42px;
					color: $secondary;
					
					text-transform: uppercase;

					&.shifted {
						font-size: 40px;	
					}

					&.mobile {
						font-size: 30px;
						margin-left: 40px;
						margin-top: -40px;
						width: 80%;
						z-index: 500;
						color: $black;
					}

					
				}

				& .detail-item {
					margin-top: 10px;
					& .detail-title {
						font-size: 18px;
						
						text-align: center;
						color: $secondary;
						text-transform: uppercase;
						margin-bottom: 5px;
						&.mobile {
							text-align: left;
							margin-left: 20px;
						}
					}
					& .detail {
						width: 79%;
						margin: auto;
						height: 90px;
						padding: 5px 5px 0px 5px;
						text-align: center;
						border-radius: 4px;
						background-color: $white;
						color: $dark;
						box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.4);
						font-size: 14px;

						&.small {
							font-size: 12px;
						}

						&.tiny {
							font-size: 10px;
						}

						&.mobile {
							width: calc(100vw - 50px);
							font-size: 16px;
							margin: auto;

							&.small {
								font-size: 11px;

							}

							&.tiny {
								font-size: 9px;
							}
						}
					}
				}
			}
		}

		
		& .mid-content {
			width: calc(100% - 80px);
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin: 0px 40px;
			margin-top: 10px;

			&.mobile {
				width: 100vw;
				margin: auto;
				flex-direction: column;
			}
			& .mid-content-left {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				width: 50%;
				&.mobile {
					width: calc(100vw - 40px);
					margin: auto;
				}
			}

			& .mid-content-right {
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				width: 50%;

				&.mobile {
					width: 100%;
				}
			}
		}
		& .bottom-content {
			width: calc(100% - 80px);
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			margin: auto;
			justify-content: space-between;

			&.mobile {
				width: 100vw;
			}
		}
		
	}

}