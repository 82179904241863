@import '../../../../styles/colors';

.list-card-container {
	width: 225px;
	height: 300px;
	user-select: none;

	&.mobile {
		width: 160px;
		height: 230px;
		margin-top: 10px;
	}
	& .list-card {
		height: 300px;
    	padding-top: 10px;
		background-color: #40403E;
		border-radius:8px;

		&.mobile {
			height: 200px;
			width: 160px;
		}

		& .list-card-title {
			width: 160px;
			margin: auto;
			color: #f4f4f4;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 20px;
			display: flex;
			justify-content: space-between;
					
			& .name {
				text-align: left;
				&.shifted {
					font-size: 12px;
				}
			}

			& .number {
				text-align: right;
			}
			
			&.mobile {
				width: 120px;
				font-size: 10px;
			}
		}

		& .list-card-header {
			width: 175px;
			height: 175px;
			margin: auto;
			border-radius: 12px;
			shape-outside: circle();
			clip-path: circle();
			
			&.rarity {
				&-1 {
					@include rarity-1-gradient;
					& canvas {
						top: 0;
					}
					&.gen-1 canvas {
						position: relative;
						top: 20px
						
					}
				}
				&-2 {
					@include rarity-2-gradient;
					& canvas {
						top: 0;
					}
					&.gen-1 canvas {
						position: relative;
						top: 20px
						
					}
				}
				&-3 {
					@include rarity-3-gradient;
					& canvas {
						top: 0;
					}
					&.gen-1 canvas {
						position: relative;
						top: 20px
						
					}
				}
				&-4 {
					@include rarity-4-gradient;
					& canvas {
						top: 0;
					}
					&.gen-1 canvas {
						position: relative;
						top: 20px
						
					}
				}
				&-5 {
					@include rarity-5-gradient;
					& canvas {
						top: 0;
					}
					&.gen-1 canvas {
						position: relative;
						top: 20px
						
					}
				}
				&-6 {
					@include rarity-6-gradient;
					& canvas {
						top: 0;
					}
					&.gen-1 canvas {
						position: relative;
						top: 20px
						
					}
				}
				&-7 {
					@include rarity-7-gradient;
					& canvas {
						top: 0;
					}
					&.gen-1 canvas {
						position: relative;
						top: 20px
						
					}
				}

				&-unknown {
					& canvas {
						top: 0;
					}
					
				}
			}
			

			&.mobile {
				width: 125px;
				height: 125px;
			}
		}

		& .list-card-footer {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			& .type-container {
				margin-left: 30px;
				margin-top: 15px;
				height: 65px;
				width: 65px;

				&.mobile {
					margin-left: 18px;
					margin-top: 4px;
					height: 40px;
					width: 40px;
				}
				& .type-title {
					font-size: 12px;
					color: $white;
					text-transform: uppercase;
					text-align: center;

					&.mobile {
						font-size: 10px;
					}
				}

				& .type-icon {
					width: 45px;
					margin: auto;
					& img {
						height:45px;
						width: 45px;
					}
					&.mobile {
						width: 30px;

						img {
							width: 30px;
							height: 30px;
						}
					}
				}
			}

			& .rarity-container {
				height: 65px;
				width: 95px;
				margin-top: 15px;
				margin-right: 20px;

				&.mobile {
					height: 40px;
					margin-top:5px;
				}
				& .rarity-box {
					margin-left: 25px;
					margin-bottom: 5px;
					height: 40px;
					width: 40px;
					border: 2px solid $white;
					border-radius: 8px;
					line-height: 40px;
					font-size: 30px;
					font-weight: bolder;
					text-align: center;

					&.mobile {
						margin-left: 50px;
						margin-bottom: -13px;
						line-height: 30px;
						height: 30px;
						width: 30px;
						font-size: 20px;

						&.rarity-2 {
							font-size: 18px;
						}

						&.rarity-4 {
							font-size: 18px;
						}


					}
					&.rarity-1 {
						background-color: $rarity1;
					}

					&.rarity-2 {
						background-color: $rarity2;
						font-size: 24px;
					}

					&.rarity-3 {
						background-color: $rarity3;
					}

					&.rarity-4 {
						background-color: $rarity4;
						font-size: 24px;
					}

					&.rarity-5 {
						background-color: $rarity5;
					}

					&.rarity-6 {
						background-color: $rarity6;
					}

					&.rarity-7 {
						background-color: $rarity7;
					}
				}

				& .rarity-title {
					width: 90px;
					font-size: 12px;
					color: $white;
					text-transform: uppercase;
					text-align: center;

					&.mobile {
						width: 45px;
						font-size: 8px;
						margin-left: 45px;
						margin-top: 14px;
					}
				}
			}
		}
		
	}
}