@import '../../../../styles/colors';

@mixin background-base() {
	position: relative;
	top: 0;
	border-radius: 12px;
	pointer-events: none;
	
}

@mixin rarity {
	&.rarity-1 {
		@include rarity-1-gradient;
	}

	&.rarity-2 {
		@include rarity-2-gradient;
	}

	&.rarity-3 {
		@include rarity-3-gradient;
	}

	&.rarity-4 {
		@include rarity-4-gradient;
	}

	&.rarity-5 {
		@include rarity-5-gradient;
	}

	&.rarity-6 {
		@include rarity-6-gradient;
	}

	&.rarity-7 {
		@include rarity-7-gradient;
	}
}
.animated-background {
	@include background-base();

	background: $white;
	box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.4);
	@include rarity;
	
}

.image-background{
	@include background-base();
	background-color: transparent;
	top: -350px;
	&.no-top {
		top: 0px;
	}
	@include rarity;
	&.grey {
		background: $dark;
	}
	&.mobile {
		top: calc(-1 * (100vw - 50px));
	}
}

.text-background {
	@include background-base();
	background-color: transparent;
	top: -700px;
}